import InputAddress from "src/components/form/InputAddress"
import common from "./common"

const yesNo = ["Sim", "Não"]

const optParentEducation = [
  "Não estudou",
  "Da 1ª a 4ª série do ensino fundamental (antigo primário)",
  "Da 5ª a 8ª série do ensino fundamental (antigo ginásio)",
  "ensino médio (antigo 2º grau) incompleto",
  "ensino médio (antigo 2º grau) completo",
  "ensino superior incompleto",
  "ensino superior completo",
  "Pós-graduação",
  "Não sei",
]

const optSchoolsTypes = [
  "Somente em escola pública",
  "Parte em escola pública e parte em escola privada",
  "Somente em escola privada",
]

const optJob = [
  "Estou trabalhando",
  "Estou procurando trabalho",
  "Não estou nem trabalhando, nem procurando trabalho",
]

const optJobPosition = [
  "Empregada/o de um estabelecimento/firma, COM carteira assinada",
  "Empregada/o de um estabelecimento/firma, SEM carteira assinada",
  "Empregada/o doméstica/o (mensalista ou diarista), COM carteira assinada",
  "Empregada/o doméstica/o (mensalista ou diarista), SEM carteira assinada",
  "Conta-própria ou autônoma/o",
  "Empregador(a) (proprietária/o, que contrata outras pessoas)",
  "Trabalhador(a) não-remunerada/o de instituição ou firma (voluntária/o ou estagiária/o)",
  "Trabalhador(a) não-remunerada/o, que ajuda em negócio da própria família",
]

const optExjob = [
  "Sim, já tive um trabalho anteriormente",
  "Não, nunca trabalhei",
]

const optCourseDecision = [
  "Sim",
  "Estou em dúvida entre dois ou mais cursos",
  "Não",
]

export default [
  common.email,
  common.name,
  common.gender,
  {
    name: "dob",
    label: "Data de nascimento",
    cleave: {
      blocks: [2, 2, 4],
      delimiters: ["/", "/"],
      numericOnly: true,
    },
    extra: {
      type: "date",
      required: true,
    },
  },
  {
    name: "race",
    label: "Como você se considera?",
    options: [
      "Preta(o)",
      "Parda(o)",
      "Branca(o)",
      "Amarela(o)",
      "Indígena",
      "Outro",
    ],
    extra: { required: true },
  },
  // Address
  {
    name: "quilomboAldeia",
    label: "Reside em quilombo ou aldeia?",
    options: yesNo,
  },
  {
    name: "country",
    label: "País",
    options: ["Angola", "Brasil", "Cabo Verde", "Guiné-Bissau"],
    extra: { required: true },
  },
  {
    name: "city",
    label: "Cidade",
    extra: { condition: ({ values }) => values.country !== "Brasil" },
  },
  {
    extra: {
      widget: InputAddress,
      condition: ({ values }) => values.country === "Brasil",
    },
  },
  // Children
  {
    name: "children",
    label: "Você tem filha/os?",
    options: yesNo,
  },
  {
    name: "children_ages",
    label: "Quantos e idade",
    extra: { condition: ({ values }) => values.children === "Sim" },
  },
  {
    name: "emancipa_with_children",
    label: "Será necessário que a criança te acompanhe ao cursinho?",
    options: yesNo,
    extra: {
      condition: ({ values }) => values.children === "Sim",
    },
  },
  // Phones
  {
    name: "phone",
    label: "fields.phone.label",
    type: "tel",
    cleave: {
      numericOnly: true,
    },
  },
  {
    name: "alt_phone",
    label: "fields.alt_phone.label",
    type: "tel",
    cleave: {
      numericOnly: true,
    },
  },
  // Tech
  {
    name: "course_informatics",
    label: "Você já fez curso de computação ou informática?",
    options: yesNo,
  },
  {
    name: "has_computer",
    label: "Você tem computadores ou notebooks em sua residência?",
    options: [
      "Não",
      "Sim, tenho 1",
      "Sim, tenho 2",
      "Sim, tenho mais do que 2",
    ],
  },
  {
    name: "has_internet",
    label: "Você tem acesso à Internet em sua residência?",
    options: yesNo,
  },
  {
    name: "ytClass",
    label: "Gostaria de participar de aulões no Youtube?",
    options: yesNo,
  },
  {
    name: "phoneClass",
    label: "Você consegue participar de aulas pelo seu celular?",
    options: yesNo,
  },
  // Schools
  {
    name: "fundamental_year",
    label: "Ano em que concluiu ou concluirá o ensino fundamental",
    type: "year",
    cleave: {
      blocks: [4],
      numericOnly: true,
    },
  },
  {
    name: "primary_school_type",
    label: "Em que tipo de escola você cursou o ensino fundamental?",
    options: optSchoolsTypes,
  },
  {
    name: "school",
    label: "Escola em que concluiu ou concluirá ensino médio",
  },
  {
    name: "school_year",
    label: "Ano em que concluiu ou concluirá o ensino médio",
    type: "year",
    cleave: {
      blocks: [4],
      numericOnly: true,
    },
  },
  {
    name: "high_school_type",
    label: "Em que tipo de escola você cursou o ensino médio?",
    options: optSchoolsTypes,
  },
  // Mother
  {
    name: "motherName",
    label: "Qual o nome da sua mãe?",
    extra: { required: true },
  },
  {
    name: "mother_can_read",
    label: "Sua mãe sabe ler e escrever?",
    options: yesNo,
  },
  {
    name: "mother_education",
    label: "Até quando sua mãe estudou?",
    options: optParentEducation,
  },
  {
    name: "mother_school",
    label:
      "Caso sua mãe tenha ensino superior (completo ou incompleto), qual o curso?",
  },
  // Father
  {
    name: "father_can_read",
    label: "Seu pai sabe ler e escrever?",
    options: yesNo,
  },
  {
    name: "father_education",
    label: "Até quando seu pai estudou?",
    options: optParentEducation,
  },
  {
    name: "father_school",
    label:
      "Caso seu pai tenha ensino superior (completo ou incompleto), qual o curso?",
  },
  // Income
  {
    name: "bolsa_familia",
    label: "Você recebe Bolsa Família?",
    options: yesNo,
    extra: { condition: ({ values }) => values.country === "Brasil" },
  },
  {
    name: "renda_familiar",
    label:
      "Somando os rendimentos de todas as pessoas que moram com você (salários, aposentadorias, bolsa família e benefícios) qual seria aproximadamente a renda mensal total no seu domicílio?",
    options: [
      "De R$ 0,00 a R$ 500,00",
      "De R$ 501,00 a R$ 1.000,00",
      "De R$ 1.001,00 a R$ 2.000,00",
      "De R$ 2.001,00 a R$ 3.000,00",
      "De R$ 3.001,00 a R$ 4.000,00",
      "De R$ 4.001,00 a R$ 5.000,00",
      "Mais de R$ 5.000,00",
    ],
    extra: { condition: ({ values }) => values.country === "Brasil" },
  },
  {
    name: "house_population",
    label: "Quantas pessoas moram na sua casa?",
    type: "number",
  },
  {
    name: "course_english",
    label: "Você já fez curso de inglês?",
    options: yesNo,
  },
  {
    name: "course_spanish",
    label: "Você já fez curso de espanhol?",
    options: yesNo,
  },
  {
    name: "course_other_language",
    label: "Você já fez curso de outra língua estrangeira?",
    options: yesNo,
  },
  {
    name: "job",
    label:
      "Atualmente, você está trabalhando ou procurando algum tipo de trabalho?",
    options: optJob,
  },
  {
    name: "exjob",
    label: "Você já teve algum trabalho antes?",
    options: optExjob,
    extra: {
      condition: ({ values }) => optJob.slice(1).indexOf(values.job) !== -1,
    },
  },
  {
    name: "job_position",
    label: "Qual seu regime de trabalho?",
    options: optJobPosition,
    extra: {
      condition: ({ values }) => values.job === optJob[0],
    },
  },
  {
    name: "exjob_position",
    label: "Neste seu trabalho anterior, você era",
    options: optJobPosition,
    extra: {
      condition: ({ values }) => values.exjob === optExjob[0],
    },
  },
  {
    name: "job_salary",
    label: "Qual é sua faixa de rendimento nesse trabalho?",
    options: [
      "De R$ 1,00 a R$ 100,00",
      "De R$ 100,01 a R$ 250,00",
      "De R$ 250,01 a R$ 500,00",
      "De R$ 500,01 a R$ 1.000,00",
      "De R$ 1.000,01 a R$ 1.500,00",
      "De R$ 1.501,01 a R$ 2.000,00",
      "De R$ 2.000,01 a R$ 3.000,00",
      "De R$ 3.000,01 a R$ 4.000,00",
      "De R$ 4.000,01 a R$ 5.000,00",
      "R$ R$ 5.000,01 ou mais",
    ],
    extra: {
      condition: ({ values }) =>
        optJobPosition.slice(0, -2).indexOf(values.job_position) !== -1,
    },
  },
  {
    name: "workload",
    label: "Quantas horas você trabalha ou trabalhava anteriormente?",
    options: [
      "Sem jornada fixa, até 10 horas semanais",
      "De 11 a 20 horas semanais",
      "De 21 a 30 horas semanais",
      "De 31 a 40 horas semanais",
      "Mais de 40 horas semanais",
    ],
    extra: {
      condition: ({ values }) =>
        values.job === optJob[0] || values.exjob === optExjob[0],
    },
  },
  {
    name: "job_reason",
    label: "Com que finalidade você trabalha ou trabalhava anteriormente?",
    options: [
      "Para ajudar meus pais nas despesas com a casa, sustentar a família",
      "Para meu sustento e o sustento de minha família (esposo/a, filhos/as etc.)",
      "Para ser independente (ter meu sustento, ganhar meu próprio dinheiro)",
      "Para adquirir experiência",
      "Para ajudar minha comunidade",
      "Outra finalidade",
    ],
    extra: {
      condition: ({ values }) =>
        values.job === optJob[0] || values.exjob === optExjob[0],
    },
  },
  {
    name: "job_duration",
    label:
      "Há quanto tempo você trabalha ou por quanto tempo trabalhou anteriormente?",
    options: [
      "Menos de 1 ano",
      "Entre 1 e 2 anos",
      "Entre 2 e 4 anos",
      "Mais de 4 anos",
    ],
    extra: {
      condition: ({ values }) =>
        values.job === optJob[0] || values.exjob === optExjob[0],
    },
  },
  {
    name: "course_decision",
    label: "Você já decidiu que curso você gostaria de fazer na universidade?",
    options: optCourseDecision,
  },
  {
    name: "course_wishes",
    label: "Qual(is) seria(m) o(s) curso(s)?",
    extra: {
      condition: ({ values }) =>
        optCourseDecision.slice(0, -1).indexOf(values.course_decision) !== -1,
    },
  },
  {
    name: "universities",
    label:
      "Por favor, cite a(s) universidade(s) em que você gostaria de estudar",
  },
]
