import React from "react"
import PropTypes from "prop-types"

import FormSubscribeMales from "src/components/FormSubscribeMales"
import Layout from "src/components/Layout"
import s from "../templates/form.module.scss"

const Page = () => {
  return (
    <Layout className={s.main}>
      <FormSubscribeMales
        cursinho={{ name: "Cursinho Popular Emancipa Malês", slug: "males" }}
        channelId="166af987-ee4a-4c58-97b5-a763d07fa207"
      />
    </Layout>
  )
}

Page.propTypes = {
  pageContext: PropTypes.object,
}

export default Page
